import React from "react"
import type { HeadFC } from "gatsby"
import styled from "@emotion/styled"
import logo from "../images/logo-white.svg"
import Footer from "../components/Footer"
import "../css/reset.css"
import "../css/global.css"
import Google from "../components/Google"
import Layout from "../components/Layout"
import { SEO } from "../components/SEO"

const Container = styled.div`
  padding: 10px;
  max-width:900px;
  margin: 0 auto;
`;

const NotFoundPage = () => {
  return <>
    <Google />
    <Layout>
      <Container>
        <h1>404 - Not Found</h1>
        <p>This page could not be found. Return <a href="/">Home</a></p>
      </Container>
    </Layout>
  </>
}

export default NotFoundPage

export const Head: HeadFC = () => <>
  <SEO title="Content not found" description="Content not found" path={"/"} />
  <meta name="robots" content="noindex" />
</>